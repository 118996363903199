import Vue from 'vue';
import Cloudinarize from '@plugins/cloudinarize';
import { initSentry } from '@utils';
import Modal from '@components/modal/modal.vue';
import ModalTarget from '@components/modal/modal-target.vue';
import SystemButton from '@components/buttons/system-button.vue';
import RegistrationForm from '@care-provider/components/registration/registration-form.vue';
import KInput from '@components/inputs/input.vue';
import KFormField from '@components/forms/form_field.vue';
import PortalVue from 'portal-vue';

Vue.use(PortalVue);

Vue.use(Cloudinarize);

document.addEventListener('DOMContentLoaded', () => {
  initSentry();

  // eslint-disable-next-line no-unused-vars
  const providerRegistrationApp = new Vue({
    el: '#authentication',
    data() {
      return {
        renderKey: 0,
      };
    },
    components: {
      KInput,
      KFormField,
      Modal,
      RegistrationForm,
      SystemButton,
      ModalTarget,
    },
  });
});
