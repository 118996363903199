var render = function render(){var _vm=this,_c=_vm._self._c;return _c('portal',{attrs:{"to":"modal"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isOpen)?_c('div',{ref:"modalContainer",staticClass:"modal",on:{"mouseup":function($event){if($event.target !== $event.currentTarget)return null;return _vm.onMouseupContainer.apply(null, arguments)},"mousedown":function($event){if($event.target !== $event.currentTarget)return null;return _vm.onMousedown.apply(null, arguments)}}},[_c('div',{ref:"dialog",staticClass:"modal__dialog",class:_vm.dialogClass,on:{"mousedown":function($event){return _vm.onMousedown(null)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.close.apply(null, arguments)}}},[(!_vm.skipClose)?_c('close-button',{ref:"closeButton",staticClass:"modal__dialog__close-button",attrs:{"color":"black"},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}}):_vm._e(),_vm._v(" "),(_vm.$slots.header)?_c('header',{staticClass:"modal__dialog__header",class:{
            'modal__dialog__header--light': _vm.light,
          }},[_vm._t("header")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal__dialog__content"},[_vm._t("default")],2),_vm._v(" "),(_vm.$slots.footer)?_c('footer',{staticClass:"modal__dialog__footer",class:{
            'modal__dialog__footer--light': _vm.footerLight,
            'modal__dialog__footer--center': _vm.footerCenter,
            'modal__dialog__footer--clear': _vm.footerClear,
            'modal__dialog__footer--clear-wide': _vm.footerClearWide,
            'modal__dialog__footer--confirmation': _vm.footerConfirmation,
            'modal__dialog__footer--space-between': _vm.footerSpaceBetween,
          }},[_vm._t("footer")],2):_vm._e()],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }