<template>
  <portal to="modal">
    <transition name="fade">
      <div
        class="modal"
        @mouseup.self="onMouseupContainer"
        @mousedown.self="onMousedown"
        ref="modalContainer"
        v-if="isOpen">
        <div
          class="modal__dialog"
          :class="dialogClass"
          ref="dialog"
          @mousedown="onMousedown(null)"
          @keydown.esc="close"
        >
          <close-button
            color="black"
            class="modal__dialog__close-button"
            ref="closeButton"
            @click.native="close"
            v-if="!skipClose"
          />
          <header
            v-if="$slots.header"
            class="modal__dialog__header"
            :class="{
              'modal__dialog__header--light': light,
            }"
          >
            <slot name="header"></slot>
          </header>
          <div class="modal__dialog__content">
            <slot></slot>
          </div>
          <footer
            v-if="$slots.footer"
            class="modal__dialog__footer"
            :class="{
              'modal__dialog__footer--light': footerLight,
              'modal__dialog__footer--center': footerCenter,
              'modal__dialog__footer--clear': footerClear,
              'modal__dialog__footer--clear-wide': footerClearWide,
              'modal__dialog__footer--confirmation': footerConfirmation,
              'modal__dialog__footer--space-between': footerSpaceBetween,
            }"
          >
            <slot name="footer"></slot>
          </footer>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import CloseButton from '@components/buttons/close-button';

export default {
  name: 'modal',
  components: {
    CloseButton,
  },
  props: {
    isOpen: {
      type: Boolean,
    },
    light: {
      type: Boolean,
    },
    footerLight: {
      type: Boolean,
    },
    footerCenter: {
      type: Boolean,
    },
    footerClear: {
      type: Boolean,
    },
    footerClearWide: {
      type: Boolean,
    },
    footerConfirmation: {
      type: Boolean,
    },
    footerSpaceBetween: {
      type: Boolean,
    },
    skipClose: {
      type: Boolean,
      default: false,
    },
    dialogClass: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      lastMousedownEl: null,
    };
  },
  watch: {
    isOpen(n, o) {
      if (n && !o) {
        document.body.style.overflowY = 'hidden';
      } else if (!n) {
        const modalsCount = document.getElementsByClassName('modal__dialog')?.length;
        if (modalsCount === 1) {
          document.body.style.overflowY = 'auto';
        }
      }
    },
  },
  beforeDestroy() {
    const modalsCount = document.getElementsByClassName('modal__dialog')?.length;
    if (modalsCount === 1) {
      document.body.style.overflowY = 'auto';
    }
  },
  mounted() {
    const { dialog, closeButton: closeButtonVNode } = this.$refs;
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const focusableContent = dialog?.querySelectorAll(focusableElements) || [];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];
    const closeButton = closeButtonVNode?.$el;

    if (closeButton) {
      closeButton.focus();
      closeButton.addEventListener('keydown', (e) => {
        const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
        if (isTabPressed && e.shiftKey) {
          if (lastFocusableElement) {
            lastFocusableElement.focus();
          }
          e.preventDefault();
        }
      });
    }

    if (lastFocusableElement) {
      lastFocusableElement.addEventListener('keydown', (e) => {
        const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
        if (isTabPressed && !e.shiftKey) {
          if (closeButton) {
            closeButton.focus();
          }
          e.preventDefault();
        }
      });
    }
  },
  created() {
    if (this.isOpen) {
      document.body.style.overflowY = 'hidden';
    }
  },
  methods: {
    onMousedown(e) {
      this.lastMousedownEl = e?.target;
    },
    onMouseupContainer() {
      if (this.lastMousedownEl !== this.$refs.modalContainer) return;
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-modal);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  overscroll-behavior: contain;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  background-color: var(--modal-bg-color);
  backdrop-filter: blur(8px);

  @media screen and (min-width: 768px) {
    padding: calc(var(--grid-unit) * 8);
  }

  &__dialog {
    position: relative;
    z-index: var(--z-modal);
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
    background: var(--gray-0);
    border: none;

    @media screen and (min-width: 768px) {
      z-index: calc(var(--z-modal) - 1);
      min-width: calc(var(--grid-unit) * 60);
      max-width: calc(var(--grid-unit) * 80);
      min-height: calc(var(--grid-unit) * 40);
      border-radius: calc(var(--grid-unit) * 4);

      &--with-margin {
        margin-top: calc(var(--grid-unit) * 7);
      }

      &--reduced-height {
        min-height: calc(var(--grid-unit) * 32);
      }

      &--reduced-width {
        max-width: calc(var(--grid-unit) * 65);
      }

      &--for-info-request {
        max-width: calc(var(--grid-unit) * 85);
      }

      &--for-search {
        max-width: calc(var(--grid-unit) * 90);
      }

      &--narrow {
        max-width: calc(var(--grid-unit) * 64);
      }

      &--for-tour-confirmation {
        .modal__dialog__content {
          text-align: center;
        }
      }
    }

    &--confirm {
      max-width: 516px;

      @media screen and (min-width: 768px) {
        min-height: calc(var(--grid-unit) * 30);
      }
    }

    &__close-button {
      position: absolute;
      top: calc(var(--grid-unit) * 2);
      right: calc(var(--grid-unit) * 2);
      text-align: center;
      padding: 0;

      @media screen and (min-width: 768px) {
        top: calc(-1.5 * var(--grid-unit));
        right: calc(-1.5 * var(--grid-unit));
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: calc(var(--grid-unit) * 9);
      padding:
        calc(var(--grid-unit) * 2)
        calc(var(--grid-unit) * 9)
        calc(var(--grid-unit) * 2)
        calc(var(--grid-unit) * 4);
      color: var(--viridian-0);
      background: var(--viridian-60);

      // TODO: need a better way to control bottom margins on heading elements
      ::v-deep :only-child {
        margin-bottom: 0;
      }

      @media screen and (min-width: 768px) {
        padding:
          calc(var(--grid-unit) * 2)
          calc(var(--grid-unit) * 4);
        text-align: center;
        border-radius:
          calc(var(--grid-unit) * 4)
          calc(var(--grid-unit) * 4)
          0 0;
        align-items: center;
      }

      &--light {
        color: var(--viridian-80);
        background: var(--viridian-0);
      }
    }

    &__content {
      flex-grow: 1;
      padding: calc(var(--grid-unit) * 2);

      @media screen and (min-width: 768px) {
        padding: calc(var(--grid-unit) * 4);

        &:last-child {
          border-radius: 0 0 calc(var(--grid-unit) * 4) calc(var(--grid-unit) * 4);
        }
      }

      > *:not(:last-child) {
        margin-bottom: calc(var(--grid-unit) * 2);

        @media screen and (min-width: 400px) {
          margin-bottom: calc(var(--grid-unit) * 3);
        }

        @media screen and (min-width: 768px) {
          margin-bottom: calc(var(--grid-unit) * 4);
        }
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: calc(var(--grid-unit) * 4);
      background: var(--viridian-0);

      .systemBtn {
        margin-right: var(--grid-unit);
        margin-bottom: 0;

        &:last-child {
          margin-right: 0;
        }
      }

      &--light {
        min-height: calc(var(--grid-unit) * 9);
        padding:
          calc(var(--grid-unit) * 2)
          calc(var(--grid-unit) * 9);
        color: var(--viridian-80);
        background: var(--viridian-0);
      }

      &--center {
        justify-content: center;
        background-color: transparent;
      }

      &--clear {
        margin: auto;
      }

      &--clear,
      &--clear-wide {
        padding-top: 16px !important;
        margin-bottom: 16px;
        background-color: var(--gray-0);
      }

      &--confirmation {
        padding-top: 0 !important;
      }

      &--space-between {
        justify-content: space-between;
      }

      @media screen and (min-width: 768px) {
        padding: calc(var(--grid-unit) * 4) calc(var(--grid-unit) * 4);
        border-radius: 0 0 calc(var(--grid-unit) * 4) calc(var(--grid-unit) * 4);
      }
    }

    &--waitlist {
      box-shadow: var(--shadow-30);
      border: 1px solid #CFE5D5;

      .modal__dialog__close-button{
        top: calc(3 * var(--grid-unit));
        right: calc( 2 * var(--grid-unit));
      }

      .modal__dialog__close-button.black{
        border: none;
      }

    }
  }
}

.login-signup-modal {
  z-index: calc(var(--z-modal) + 50);
  display: none;
  overflow: hidden;
}

.medium-modal {
  max-width: 580px;
}

.leads-optin-modal {
  margin-top: 50px;

  @media screen and (max-width: 425px) {
    margin-top: 0;
  }

  .modal__dialog__footer {
    padding: calc(var(--grid-unit) * 2) calc(var(--grid-unit) * 4);
  }
}
</style>
