<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'registration-form',
  mounted() {
    setInterval(window.initSignUp, 500);
  },
};
</script>
